import { basePath } from "next.config";
import { useRouter } from "next/router";
import React from "react";
import SidebarContent from "./SidebarContent";

const Sidebar = (props) => {
  const router = useRouter();
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box cursor-pointer" onClick={() => router.push("/")}>
          <div className="logo logo-light">
            <span className="logo-sm">
              <img src={basePath + "/images/blissclub_logo.jpeg"} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img
                src={basePath + "/images/blissclub_logo.jpeg"}
                alt="logo"
                height="27"
              />
            </span>
          </div>
        </div>
        <p className="text-white text-center">BLISSCLUB DASHBOARD</p>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
