export default function AccessDenied() {
    return (
        <div className="page-content">
            <div className="w-100" />
            <div className="d-flex flex-column align-items-center justify-content-center">
                <i className="fas fa-times text-white bg-danger p-2" style={{ fontSize: "50px" }} />
                <h1 className="mt-3">Access Denied</h1>
                <p className="mb-1 mt-3">You do not have permission to view this page</p>
                <p className="mb-3">Please contact your admin to get access of this page</p>
                <h4 className="text-danger">Error code:403</h4>
            </div>
        </div>
    );
}