
module.exports = {
        images: {
                deviceSizes: [640, 750, 828, 1080, 1200, 1920, 2048, 3840],
                imageSizes: [16, 32, 48, 64, 96, 128, 256, 384],
                domains: ['s3.ap-south-1.amazonaws.com', 'cdn.shopify.com', 's3.s3region.amazonaws.com', 'dev-blisclub.s3.ap-south-1.amazonaws.com',
                        'qa-blissclub.s3.ap-south-1.amazonaws.com', 'uat-blissclub.s3.ap-south-1.amazonaws.com','prod-blissclub.s3.ap-south-1.amazonaws.com'
                ],
        },
        productionBrowserSourceMaps: true,
        basePath: ""
}