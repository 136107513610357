import EmptyLayout from "./EmptyLayout";
import Layout from "./Layout";
const LayoutConfig = [
  {
    path: "/preview",
    layout: EmptyLayout,
  },
  {
    path: "/login",
    layout: EmptyLayout,
  },
  {
    path: "/",
    layout: Layout,
  },
  {
    path: "/zones",
    layout: Layout,
  },
  {
    path: "",
    layout: EmptyLayout,
  },
];

const getLayout = (path) => {
  let config = LayoutConfig.find((conf) => path.includes(conf.path));
  if (config) return config.layout;
  else return EmptyLayout;
};

export { getLayout };
