/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);

  return true;
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass, action = "toggle") {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

// helper functions starts

// Change sidebar theme
function changeLeftSidebarTheme(theme) {
  try {
    changeBodyAttribute("data-sidebar", theme);
  } catch (error) {}
}

/**
 * Changes the left sidebar theme Image
 */
function changeLeftSidebarThemeImage(theme) {
  try {
    changeBodyAttribute("data-sidebar-image", theme);
  } catch (error) {}
}
/**
 * Changes the left sidebar theme Image
 */
function changeTopbarTheme(theme) {
  try {
    changeBodyAttribute("data-topbar", theme);
  } catch (error) {}
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
function changeLeftSidebarType(sidebarType, isMobile) {
  try {
    switch (sidebarType) {
      case "compact":
        changeBodyAttribute("data-sidebar-size", "small");
        manageBodyClass("sidebar-enable", "remove");
        manageBodyClass("vertical-collpsed", "remove");
        break;
      case "icon":
        changeBodyAttribute("data-sidebar-size", "");
        changeBodyAttribute("data-keep-enlarged", "true");
        manageBodyClass("vertical-collpsed", "add");
        break;
      case "condensed":
        manageBodyClass("sidebar-enable", "add");
        if (window.screen.width >= 992) {
          manageBodyClass("vertical-collpsed", "remove");
          manageBodyClass("sidebar-enable", "remove");
          manageBodyClass("vertical-collpsed", "add");
          manageBodyClass("sidebar-enable", "add");
        } else {
          manageBodyClass("sidebar-enable", "add");
          manageBodyClass("vertical-collpsed", "add");
        }
        break;
      default:
        changeBodyAttribute("data-sidebar-size", "");
        manageBodyClass("sidebar-enable", "remove");
        if (!isMobile) manageBodyClass("vertical-collpsed", "remove");
        break;
    }
  } catch (error) {}
}

export {
  changeLeftSidebarTheme,
  changeLeftSidebarThemeImage,
  changeTopbarTheme,
  changeLeftSidebarType,
};
