import hasPermission from 'lib/has-permission';
import { useEffect, useState } from 'react';
import { getAuth, isAuthenticated } from '../../services/identity.service';
import Layout from '../Layout/Layout';
import AccessDenied from '../Utilities/AccessDenied';
import { isProtected } from "./Security";

const isBrowser = () => typeof window !== "undefined";

const WithAuth = ({ router, children }) => {
  const [page, setPage] = useState(0);
  useEffect(() => {
    const auth = getAuth();
    if (isBrowser() && !isAuthenticated(auth) && isProtected(router.pathname)) {
      window.location.replace('/login');
    }
    else if (isBrowser() && isAuthenticated(auth) && !isProtected(router.pathname)) {
      window.location.replace('/')
    }
    else if (isBrowser() && isAuthenticated(auth) && !hasPermission(router.pathname)) {
      setPage(1);
    }
    else setPage(2);
  }, [])
  return <>
    {page === 0 ? null : <>{page === 1 ? <Layout><AccessDenied /></Layout> : children}</>}
  </>;
};

export default WithAuth;
