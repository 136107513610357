import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const setAuth = (authObject) => {
  cookies.set('AUTH_ADMIN', JSON.stringify(authObject), { path: '/' });
  return authObject;
}
export const setRefreshToken = (refreshToken) => {
  cookies.set('RT', refreshToken, { path: '/' });
  return refreshToken;
}
export const getAuth = () => {
  const auth = cookies.get('AUTH_ADMIN');
  return auth
}
export const getRefreshToken = () => {
  const auth = cookies.get('RT');
  return auth
}
export const removeAuth = () => {
  cookies.remove('AUTH_ADMIN', { path: '/' });
  return;
}
export const removeRT = () => {
  cookies.remove('RT', { path: '/' });
  return;
}
export const isAuthenticated = (user) => {
  return user != null && user.token;
}
