import { withRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { removeAuth, removeRT } from "services/identity.service";
import SimpleBar from "simplebar-react";

import Link from "next/link";
import navigation from "./leftNavData";

const SidebarContent = (props) => {
  const showNestedChildren = (child, expanded) => {
    if (!child) return "";
    if (expanded) return "has-arrow";
    return "mm-show has-arrow";
  }
  const [isToggled, setIsToggled] = useState(true);
  const [leftNavigation, setleftNavigation] = useState([]);

  useEffect(() => {
    document.setIsToggled = setIsToggled;
    setleftNavigation(navigation);
  }, [])
  return (
    <React.Fragment>
      <SimpleBar className="h-100" >
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {Object.keys(leftNavigation).map((key) => {
              return (<div key={key}>
                <li className="menu-title">{key}</li>
                {leftNavigation[key].map((item) => {
                  return (
                    <li key={item.name}>
                      <Link href={item.route || props.router.asPath}>
                        <a className={showNestedChildren(item.children, item.expanded)}
                          onClick={() => {
                            item.expanded = !item.expanded;
                            setleftNavigation(leftNavigation)
                          }}>
                          {item.icon}
                          {isToggled && <span>{item.name}</span>}
                        </a>
                      </Link>
                      {item.children && item.expanded &&
                        <ul className="sub-menu" aria-expanded="false">
                          {item.children.map((submenu, index3) => {
                            if (submenu.children) {
                              return <li key={submenu.name}>
                                <Link href={submenu.route || props.router.asPath}>
                                  <a className={showNestedChildren(submenu.children, submenu.expanded)}
                                    onClick={() => {
                                      submenu.expanded = !submenu.expanded;
                                      setleftNavigation(leftNavigation)
                                    }}>
                                    {submenu.icon}
                                    {isToggled && <span>{submenu.name}</span>}
                                  </a>
                                </Link>
                                {submenu.children && submenu.expanded &&
                                  <ul className="sub-menu" aria-expanded="false">
                                    {submenu.children.map((submenu1) => {
                                      if (submenu1.children) {
                                        return <li key={submenu1.name}>
                                          <Link href={submenu1.route || props.router.asPath}>
                                            <a className={showNestedChildren(submenu1.children, submenu1.expanded)}
                                              onClick={() => {
                                                submenu1.expanded = !submenu1.expanded;
                                                setleftNavigation(leftNavigation)
                                              }}>
                                              {submenu1.icon}
                                              {isToggled && <span>{submenu1.name}</span>}
                                            </a>
                                          </Link>
                                          {submenu1.children && submenu1.expanded &&
                                            <ul className="sub-menu" aria-expanded="false" style={{ paddingLeft: "8px" }}>
                                              {submenu1.children.map((submenu11) => {
                                                return (<li key={submenu11.name}>
                                                  <Link href={submenu11.route}>
                                                    <a className={props.router.asPath === (submenu11.route) ? "mm-active" : ""}>{submenu11.name}</a>
                                                  </Link>
                                                </li>)
                                              })}
                                            </ul>}
                                        </li>
                                      }
                                      return (<li key={submenu1.name}>
                                        <Link href={submenu1.route}>
                                          <a className={props.router.asPath === (submenu1.route) ? "mm-active" : ""}>{submenu1.name}</a>
                                        </Link>
                                      </li>)
                                    })}
                                  </ul>}
                              </li>
                            }
                            return (<li key={submenu.name}>
                              <Link href={submenu.route}>
                                <a className={props.router.asPath === (submenu.route) ? "mm-active" : ""}>{submenu.name}</a>
                              </Link>
                            </li>)
                          })}
                        </ul>}
                    </li>
                  )
                })}
              </div>)
            })}
            <li className="">
              <Link href={"/login"}>
                <a
                  onClick={() => {
                    removeAuth();
                    removeRT();
                  }}>
                  <i className="bx bx-log-out-circle"></i>
                  <span>LOGOUT!</span>
                </a></Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default withRouter(SidebarContent);
