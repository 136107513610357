const ApiUrl = process.env.NEXT_PUBLIC_API_URL;
// module.exports = url;

const UrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `${ApiUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};

export const GET_ALL_PUBLIC_COUPON_URL = UrlParamsReplace("/admin/coupons");
export const CREATE_OR_UPDATE_PUBLIC_COUPON_URL =
  UrlParamsReplace("/admin/coupons");
export const GET_COUPON_BY_SEARCHTEXT_URL = (searchText) =>
  UrlParamsReplace("/admin/coupons/:searchText", { searchText });

export const ADMIN_GOOGLE_LOGIN_URL = () =>
  UrlParamsReplace("/auth/google?state=admin");
export const DUMP_LOGS_TO_AWS_CLOUD_WATCH_URL =
  UrlParamsReplace("/dump-logs/aws");
export const VERIFY_TOKEN_URL = () => UrlParamsReplace("/auth/verify");
export const GET_ALL_MEMBER_FILTER_URL = UrlParamsReplace("/members/filter");
export const DELETE_MEMBER_URL = (id) =>
  UrlParamsReplace("/members/:id", { id });
export const CREATE_MEMBER_URL = () => UrlParamsReplace("/members");
export const EDIT_MEMBER_BY_ID_URL = (id) =>
  UrlParamsReplace("/members/:id", { id });
export const GET_MEMBER_BY_ID_URL = (id) =>
  UrlParamsReplace("/members/:id", { id });

export const GET_INFO_CARD_URL = (offset, limit, searchText) =>
  UrlParamsReplace(
    "/admin/info-card?offset=:offset&limit=:limit&searchText=:searchText",
    { offset, limit, searchText }
  );
export const CREATE_INFO_CARD_URL = () => UrlParamsReplace("/admin/info-card");
export const UPDATE_INFO_CARD_URL = (id) =>
  UrlParamsReplace("/admin/info-card/:id", { id });
export const GET_INFO_CARD_BY_ID_URL = (id) =>
  UrlParamsReplace("/admin/info-card/:id", { id });

export const GET_UPLOAD_PRE_SIGNED_URL = UrlParamsReplace(
  "/aws/get-upload-pre-signed-url"
);
export const GET_PRE_SIGNED_URL = (fileKey) =>
  UrlParamsReplace("/aws/get-pre-signed-url?fileKey=:fileKey", { fileKey });

export const GET_ALL_PRODUCT_INFO_CARD_FILTER_URL = (
  offset,
  limit,
  searchText
) =>
  UrlParamsReplace(
    "/admin/product-info-card?offset=:offset&limit=:limit&searchText=:searchText",
    { offset, limit, searchText }
  );
export const EDIT_PRODUCT_INFO_CARD_BY_ID_URL = (id) =>
  UrlParamsReplace("/admin/product-info-card/:id", { id });
export const GET_PRODUCTS_BY_SEARCHTEXT_URL = (searchText) =>
  UrlParamsReplace("/admin/products?searchText=:searchText", { searchText });
export const GET_SIMILAR_PRODUCT_OPTIONS = ({ productId, searchText }) =>
  UrlParamsReplace(
    "/admin/similar-product-options/:productId?searchText=:searchText",
    { productId, searchText }
  );
export const CREATE_PRODUCT_INFO_CARD_URL = () =>
  UrlParamsReplace("/admin/product-info-card");
export const EDIT_PRODUCT_INFO_CARD_URL = (id) =>
  UrlParamsReplace("/admin/product-info-card/:id", { id });
export const GET_ALL_SIMILAR_PRODUCT_FILTER_URL = UrlParamsReplace(
  "/similar-products/filter"
);
export const DELETE_SIMILAR_PRODUCT_URL = (id) =>
  UrlParamsReplace("/similar-products/:id", { id });
export const CREATE_SIMILAR_PRODUCT_URL = () =>
  UrlParamsReplace("/similar-products");
export const EDIT_SIMILAR_PRODUCT_BY_ID_URL = (id) =>
  UrlParamsReplace("/similar-products/:id", { id });
export const GET_SIMILAR_PRODUCT_BY_ID_URL = (id) =>
  UrlParamsReplace("/similar-products/:id", { id });

export const GET_ALL_SIZE_URL = (offset, limit) =>
  UrlParamsReplace("/sizefilters?offset=:offset&limit=:limit", {
    offset,
    limit,
  });
export const GET_ALL_ACTIVITY_URL = (offset, limit) =>
  UrlParamsReplace("/activities?offset=:offset&limit=:limit", {
    offset,
    limit,
  });
export const CREATE_HOME_PAGE_ACTIVITY_URL = () =>
  UrlParamsReplace("/activities");
export const GET_ALL_ADMIN_ACTIVITY_URL = (offset, limit) =>
  UrlParamsReplace("/admin/activities?offset=:offset&limit=:limit", {
    offset,
    limit,
  });
export const DELETE_ACTIVITY_URL = (id) =>
  UrlParamsReplace("/activities/:id", { id });
export const UPDATE_ACTIVITY_STATUS_URL = (id) =>
  UrlParamsReplace("/activities/:id/status", { id });
export const UPDATE_ACTIVITY_URL = (id) =>
  UrlParamsReplace("/activities/:id", { id });
export const UPDATE_ACTIVITY_LOGOURL = (id) =>
  UrlParamsReplace("/activities/:id/logoUrl", { id });
export const UPDATE_HOME_PAGE_ACTIVITY_ORDER_URL = () =>
  UrlParamsReplace("/activities/order");
export const GET_ALL_CATEGORY_URL = (offset, limit) =>
  UrlParamsReplace("/categories?offset=:offset&limit=:limit", {
    offset,
    limit,
  });
export const CREATE_HOME_PAGE_CATEGORY_URL = () =>
  UrlParamsReplace("/categories");
export const GET_ALL_ADMIN_CATEGORY_URL = (offset, limit) =>
  UrlParamsReplace("/admin/categories?offset=:offset&limit=:limit", {
    offset,
    limit,
  });
export const DELETE_CATEGORY_URL = (id) =>
  UrlParamsReplace("/categories/:id", { id });
export const UPDATE_CATEGORY_STATUS_URL = (id) =>
  UrlParamsReplace("/categories/:id/status", { id });
export const UPDATE_CATEGORY_URL = (id) =>
  UrlParamsReplace("/categories/:id", { id });
export const UPDATE_HOME_PAGE_CATEGORY_ORDER_URL = () =>
  UrlParamsReplace("/categories/order");
export const CREATE_HOME_PAGE_SIZE_URL = () => UrlParamsReplace("/sizefilters");
export const GET_ALL_ADMIN_SIZE_URL = (offset, limit) =>
  UrlParamsReplace("/admin/sizefilters?offset=:offset&limit=:limit", {
    offset,
    limit,
  });
export const DELETE_SIZE_URL = (id) =>
  UrlParamsReplace("/sizefilters/:id", { id });
export const UPDATE_SIZE_STATUS_URL = (id) =>
  UrlParamsReplace("/sizefilters/:id/status", { id });
export const UPDATE_SIZE_URL = (id) =>
  UrlParamsReplace("/sizefilters/:id", { id });
export const UPDATE_HOME_PAGE_SIZE_ORDER_URL = () =>
  UrlParamsReplace("/sizefilters/order");
export const CREATE_HOME_PAGE_SUBCATEGORY_URL = () =>
  UrlParamsReplace("/sub-categories");
export const GET_ALL_SUBCATEGORY_BY_CATEGORY_URL = (id) =>
  UrlParamsReplace("/admin/categories/:id/sub-categories", { id });
export const DELETE_SUBCATEGORY_URL = (id) =>
  UrlParamsReplace("/sub-categories/:id", { id });
export const UPDATE_SUBCATEGORY_STATUS_URL = (id) =>
  UrlParamsReplace("/sub-categories/:id/status", { id });
export const UPDATE_SUBCATEGORY_URL = (id) =>
  UrlParamsReplace("/sub-categories/:id", { id });
export const UPDATE_HOME_PAGE_SUBCATEGORY_ORDER_URL = () =>
  UrlParamsReplace("/sub-categories/order");
export const GET_COLLECTION_BY_SEARCH_TEXT_URL = (searchText) =>
  UrlParamsReplace("/collection?searchText=:searchText", { searchText });
export const CREATE_HOME_PAGE_BANNER_URL = () =>
  UrlParamsReplace("/admin/banners");
export const UPDATE_HOME_PAGE_BANNER_URL = (bannerId) =>
  UrlParamsReplace("/admin/banners/:bannerId", { bannerId });
export const BULK_UPDATE_HOME_PAGE_BANNER_ORDER_URL = (bannerId) =>
  UrlParamsReplace("/admin/banner-display-order");
export const GET_ALL_HOME_PAGE_BANNER_URL = (
  offset,
  limit,
  isActive,
  bannerType
) =>
  UrlParamsReplace(
    "/admin/banners-by-filter?offset=:offset&limit=:limit&isActive=:isActive&type=:bannerType",
    { offset, limit, isActive, bannerType }
  );
export const GET_ALL_BANNER_URL = (offset, limit, isActive, bannerType) =>
  UrlParamsReplace(
    "/admin/banners?offset=:offset&limit=:limit&isActive=:isActive&type=:bannerType",
    { offset, limit, isActive, bannerType }
  );
export const DELETE_BANNER_URL = (bannerId) =>
  UrlParamsReplace("/admin/banners/:bannerId", { bannerId });

export const GET_ALL_CART_SIMILAR_PRODUCT_URL = (offset, limit, searchText) =>
  UrlParamsReplace(
    "/admin/cart-similar-product?offset=:offset&limit=:limit&searchText=:searchText",
    { offset, limit, searchText }
  );
export const CREATE__UPDATE_CART_SIMILAR_PRODUCT_URL = () =>
  UrlParamsReplace("/admin/cart-similar-product");

export const GET_ALL_PRODUCT_SECTION_FILTER_URL = (offset, limit, searchText) =>
  UrlParamsReplace(
    "/product-section?offset=:offset&limit=:limit&searchText=:searchText",
    { offset, limit, searchText }
  );
export const GET_PRODUCT_SECTION_BY_ID_URL = (id) =>
  UrlParamsReplace("/product-section/:id", { id });
export const CREATE_PRODUCT_SECTION_URL = () =>
  UrlParamsReplace("/product-section");
export const EDIT_PRODUCT_SECTION_URL = (id) =>
  UrlParamsReplace("/product-section/:id", { id });

export const GET_MARKET_LINE_TEXT_URL = UrlParamsReplace("/marketing-line");
export const CREATE_MARKET_LINE_TEXT_URL = UrlParamsReplace(
  "/admin/marketing-line"
);

export const CREATE_CONFIG_URL = UrlParamsReplace("/configs");

export const GET_ALL_PRODUCT_METAFIELDS_URL = UrlParamsReplace("/products/metafield");
export const GET_ALL_PRODUCT_METAFIELD_VALUES_URL= ({collectionId}) => UrlParamsReplace("/products/metafieldValues?collectionId=:collectionId", {collectionId});

export const UPDATE_CATEGORY_FILTER_URL = (id) => UrlParamsReplace("/admin/categories/:id/filters", { id });
export const UPDATE_ACTIVITY_FILTER_URL = (id) => UrlParamsReplace("/admin/activities/:id/filters", { id });
export const GET_CONFIG_BY_KEY_URL = (key) =>
  UrlParamsReplace("/configs/:key", { key });

export const UPDATE_CONFIG_URL = (key) =>
  UrlParamsReplace("/configs/:key", { key });
export const CREATE_HOME_PAGE_PROMINENT_CATEGORY_URL = () =>
  UrlParamsReplace("/admin/prominent-categories");
export const GET_ALL_ADMIN_PROMINENT_CATEGORY_URL = (offset, limit) =>
  UrlParamsReplace("/admin/prominent-categories?offset=:offset&limit=:limit", {
    offset,
    limit,
  });
export const DELETE_PROMINENT_CATEGORY_URL = (id) =>
  UrlParamsReplace("/admin/prominent-categories/:id", { id });
export const UPDATE_PROMINENT_CATEGORY_STATUS_URL = (id) =>
  UrlParamsReplace("/admin/prominent-categories/:id/status", { id });
export const UPDATE_PROMINENT_CATEGORY_URL = (id) =>
  UrlParamsReplace("/admin/prominent-categories/:id", { id });
export const UPDATE_HOME_PAGE_PROMINENT_CATEGORY_ORDER_URL = () =>
  UrlParamsReplace("/admin/prominent-categories/order");
