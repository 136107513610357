import { getAuth } from "services/identity.service";

const role = getAuth()?.role;
const pagePermissions = {
  "/members": ["super_admin"],
  "/members/action": ["super_admin"],
  "/categories": ["super_admin", "admin"],
  "/banners": ["super_admin", "admin"],
  "/banners/action": ["super_admin", "admin"],
  "/secondary-banners": ["super_admin", "admin"],
  "/secondary-banners/action": ["super_admin", "admin"],
  "/info-cards": ["super_admin", "admin"],
  "/info-cards/action": ["super_admin", "admin"],
  "/products-info-cards": ["super_admin", "admin"],
  "/products-info-cards/action": ["super_admin", "admin"],
  "/similar-products": ["super_admin", "admin"],
  "/similar-products/action": ["super_admin", "admin"],
  "/cart-similar-product": ["super_admin", "admin"],
  "/cart-similar-product/action": ["super_admin", "admin"],
  "/coupons": ["super_admin", "admin"],
  "/disable-section": ["super_admin", "admin"],
  "/disable-section/action": ["super_admin", "admin"],
  "/marketing-line": ["super_admin", "admin"],
  "/widgets": ["super_admin"],
  "/widgets/action": ["super_admin"],
  "/categories/action": ["super_admin", "admin"],
  "/categories/[categoryId]/sub-categories": ["super_admin", "admin"],
  "/categories/[categoryId]/sub-categories/action": ["super_admin", "admin"],
  "/filters": ["super_admin", "admin"],
  "/filters/action": ["super_admin", "admin"],
  "/activities": ["super_admin", "admin"],
  "/activities/action": ["super_admin", "admin"],
  "/andriodNotification": ["super_admin", "admin"],
  "/iosNotification": ["super_admin", "admin"],
};

export default function hasPermission(page) {
  if (
    ["/", "/login", "/success", "/terms-pages/privacy", "/_error"].some(
      (url) => url === page
    )
  )
    return true;
  const roles = pagePermissions[page];
  return roles?.some((name) => name === role);
}
