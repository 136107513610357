import React, { useEffect } from "react";
import { dumpLogsToAwsCloudWatch } from "services/logger.service";
import {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSideBarThemeTypes,
} from "./constants/layout";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";

import {
  changeLeftSidebarTheme,
  changeLeftSidebarThemeImage,
  changeTopbarTheme,
  changeLeftSidebarType,
} from "./components/layoutHelperFunctions";

const layoutType = layoutTypes.VERTICAL;
const layoutWidth = layoutWidthTypes.FLUID;
const leftSideBarTheme = leftSideBarThemeTypes.DARK;
const leftSideBarThemeImage = leftBarThemeImageTypes.NONE;
const leftSideBarType = "default";
const topbarTheme = topBarThemeTypes.LIGHT;
const isPreloader = false;
const showRightSidebar = false;
const showSidebar = true;
const leftMenu = false;

const Layout = (props) => {

  function logError(msg, url, lineNo, columnNo, error) {
    const errorMessage = msg.toLowerCase();
    let errorType = 'BrowserError: ';

    if (errorMessage.includes('script error')) {
      errorType = 'Script Error: ';
    }
    const body = {
      logMessage: errorType + msg,
      logData: {
        url,
        error: { message: error.message, stack: error.stack },
        lineNo,
        columnNo,
        location: {
          host: window.location.host,
          hostname: window.location.hostname,
          href: window.location.href,
          origin: window.location.origin,
          pathname: window.location.pathname
        }
      }
    }
    dumpLogsToAwsCloudWatch(body);
  }
 
   useEffect(() => {
    window.onerror = logError;
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (leftSideBarTheme) {
      changeLeftSidebarTheme(leftSideBarTheme);
    }
  }, [leftSideBarTheme]);

  useEffect(() => {
    if (leftSideBarThemeImage) {
      changeLeftSidebarThemeImage(leftSideBarThemeImage);
    }
  }, [leftSideBarThemeImage]);

  useEffect(() => {
    if (leftSideBarType) {
      changeLeftSidebarType(leftSideBarType);
    }
  }, [leftSideBarType]);

  useEffect(() => {
    if (topbarTheme) {
      changeTopbarTheme(topbarTheme);
    }
  }, [topbarTheme]);

  return (
    <React.Fragment>

      <div id="layout-wrapper">
        <Sidebar />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Layout;
